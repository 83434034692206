#kt_login_signin_form, #kt_login_password_reset_form {
    input.form-control[name="password"] {
        background-position: 92% center;
    }
    i.bi {
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 18px;
        cursor: pointer;
    }
}
.auth-block {
    background: #fff;
    border-radius: 8px;
}