.toolbar { 
    .profile-logo {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}

.logo_img  {
    filter: invert(1);
    width: 160px;
    margin-top: 10px;
}