.classesTabWrap {   
  label {
      font-family: "Cabin";
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      margin-bottom: 12px;
      color: #3C3C3C;
      text-transform: capitalize;
  }
  .bgFormColor {
      background: #fafafa;
      border-radius: 8px;
      .statusCheck {
          height: 60px;
          padding: 15px 24px;
          border-radius: 12px;
          border: 1px solid rgb(233, 233, 233);
          font-family: "Cabin";
          background-color: rgb(236, 236, 236);
          color: #505050;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.002em;
      }
      .form-check-input {
          &:checked {
              border-color: #2194FF;
              background-color: #2194FF;
          }
      }
      .group-img.iconLeft.textareaWrap {
          label {
              top: 20px;
          }
          textarea.addressTextarea.form-control {
              padding-left: 40px;
              background-color: rgb(236, 236, 236);
          }
      }
      ul.daysCheckbox {
          margin: 0 0 8px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          li {
              display: flex;
              label {
                  padding: 12px 15px;
                  margin-bottom: 0 !important;
                  border: 1px solid #F2F4F7;
                  background: #fff;
                  border-radius: 50px;
                  position: relative;
                  z-index: 2;
                  display: flex;
                  align-items: center;
                  input[type=checkbox] {
                      height: auto;
                      margin-right: 8px;
                      position: relative;
                      z-index: 2;
                      width: 16px;
                      height: 16px;
                      &:checked {
                          accent-color: #fff;
                      }
                      &:checked + span.day {
                          color: #fff;
                      }
                      &:checked + span.day + span.bg {
                          background: #2194FF;
                          border-radius: 50px;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          position: absolute;
                          z-index: -1;
                      }
                  }
                  span.day {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 19.6px;
                      color: #2B2B2B;
                  }
              }
          }
      }
      .statusCheck {
          label {
              z-index: 2;
              font-weight: 300;
              input[type="radio"] {
                  position: relative;
                  margin-right: 5px;
                  z-index: 2;
                  width: 20px;
                  height: 20px;
                  &:checked {
                      accent-color: #fff;
                  }
                  &:checked + .radioText + span.bgBlue {
                      background: #2194FF;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      border-radius: 12px;
                      z-index: -1;
                  }
                  &:checked + .radioText + span.bgBlue + .addOption {
                      .btnsWrap {
                          svg {
                              fill: #fff;
                              path {
                                  fill: #fff;
                              }
                          }
                      }
                  }
                  &:checked + .radioText {
                      color: #fff;
                  }
                  .radioText {
                      position: relative;
                      z-index: 2;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 19.6px;
                      letter-spacing: -0.002em;
                      text-align: left;
                      color: #2B2B2B;
                  }
              }
              .addOption {
                  display: flex;
                  gap: 5px;
                  position: absolute;
                  right: 10px;
                  z-index: 3;
                  input {
                      width: 35px;
                      background-color: #fff;
                      border: 1px solid #fff;
                      border-radius: 4px;
                      text-align: center;
                      font-size: 18px;
                      line-height: normal;
                  }
                  .btnsWrap {
                      display: flex;
                      flex-direction: column;
                      button {
                          background: transparent;
                          border: none;
                      }
                      button.rotate {
                          svg {
                              transform: rotate(180deg);
                          }
                      }
                  }
              }
              .addOccurenceOption {
                  display: flex;
                  gap: 5px;
                  position: absolute;
                  right: 10px;
                  z-index: 3;
                  input {
                      width: 100px;
                      background-color: #fff;
                      border: 1px solid #fff;
                      border-radius: 4px;
                      text-align: center;
                      font-size: 14px;
                      line-height: normal;
                  }
                  .btnsWrap {
                      display: flex;
                      flex-direction: column;
                      button {
                          background: transparent;
                          border: none;
                      }
                      button.rotate {
                          svg {
                              transform: rotate(180deg);
                          }
                      }
                  }
              }
          }
          .dateShow {
              position: relative;
              &:after {
                  content: "";
                  position: absolute;
                  background: #fff;
                  width: 90px;
                  height: 40px;
                  left: 16px;
                  z-index: 0;
                  top: 10px;
                  border-radius: 4px;
              }
              input[type="date"] {
                  background-color: transparent;
                  border: none;
                  position: relative;
                  z-index: 2;
              }
          }
      }
      ul.daysRadioBox {
          margin: 8px 0;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          li {
              display: flex;
              label {
                  padding: 12px 15px;
                  margin-bottom: 0 !important;
                  border: 1px solid #F2F4F7;
                  background: #fff;
                  border-radius: 50px;
                  position: relative;
                  z-index: 2;
                  display: flex;
                  align-items: center;
                  input[type=radio] {
                      height: auto;
                      margin-right: 8px;
                      position: relative;
                      z-index: 2;
                      width: 16px;
                      height: 16px;
                      &:checked {
                          accent-color: #fff;
                      }
                      &:checked + span.day {
                          color: #fff;
                      }
                      &:checked + span.day + span.bg {
                          background: #2194FF;
                          border-radius: 50px;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          position: absolute;
                          z-index: -1;
                      }
                  }
                  span.day {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 19.6px;
                      color: #2B2B2B;
                  }
              }
          }
      }
      ul.outerBlock {
          ul.showImages {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              li {
                  width: calc(20% - 10px);
                  button.crossBtn {
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      border: none;
                      background: transparent;
                      padding: 0;
                  }
                  .image {
                      img {
                          border-radius: 16px;
                          height: 125px;
                          object-fit: cover;
                      }
                  }
              }
              .uploadBlock {
                  border: 1px dashed #D9DBDE;
                  background: #FFFFFF;
                  border-radius: 16px;
                  width: calc(20% - 10px);
                  .upload {
                      padding: 10px 15px;
                      position: relative;
                      input[type=file] {
                          position: absolute;
                          opacity: 0;
                          height: 100%;
                          width: 100%;
                          cursor: pointer;
                          left: 0;
                          right: 0;
                          top: 0;
                      }
                      p {
                          color: #000;
                          font-size: 14px;
                      }
                      button {
                          background: #000;
                          border: 1px solid #000;
                          padding: 8px 20px;
                          border-radius: 50px;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 19.2px;
                          color: #fff;
                      }
                  }
              }
          }
      }
      ul.addedTime {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          li {
              background: #EAEAEA;
              border-radius: 20px;
              padding: 5px 10px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              button {
                  background: transparent;
                  border: none;
                  padding: 0;
              }
              label {
                  margin-bottom: 0 !important;
                  padding-left: 5px;
                  font-size: 14px !important;
                  color: #2B2B2B;
              }
          }
      }
      .group-img.iconLeft.rightLeft {
          input.commonInput.form-control {
              padding-left: 40px;
              padding-right: 70px;
          }
          span.person {
              position: absolute;
              right: 15px;
              top: 49%;
              transform: translateY(-50%);
          }
          .icon-posoition{
            position: absolute;
            top: 14%;
            left: 3%;
          }
      }
  }
  button.saveBtn {
      background: #000;
      color: #fff;
      padding: 10px 25px;
      border: 1px solid #000;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.20000000298023224px;
      text-align: center;
      width: 240px;
      height: 64px;
  }
  .policyLink {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      text-align: center;
      text-decoration-line: underline;
      color: #121C27;
  }
}
form {
  label {
      span {
          color: #2B2B2B !important;
      }
  }
}
.addOccurenceOption {
  input {
      height: 45px;
  }
}
.errorMessage{
    color: #dc3545!important;
    font-size: 12px !important;
    padding-top: 2px;
}

.addClassModal {
    .uploadWrapper {
        ul {
            padding: 0;
            list-style: none;
        }
    }
    .classesTabWrap {
        .bgFormColor {
            .statusCheck {
                .dateShow {
                    &:after {
                        left: 4px;
                        top: 10px;
                    }
                }
            }
            .group-img.iconLeft.rightLeft {
                    .icon-posoition {
                        top: 50% !important;
                        padding: 0 !important;
                        margin: 0 !important;
                        transform: translateY(-50%);
                    }
            }
            .group-img.iconLeft.textareaWrap {
                label {
                    top: 10px;
                    position: absolute;
                    left: 10px;
                }
            }
        }
    }
}


// Media Qury start 
@media only screen and (max-width: 1400px) {
  .classesTabWrap {
      .bgFormColor {
          ul.outerBlock {
              ul.showImages {
                  li,.uploadBlock {
                      width: calc(25% - 10px);
                  }
              }
          }
      }
  }
}
@media only screen and (max-width: 1199px) {
  .classesTabWrap {
      .bgFormColor {
          ul.outerBlock {
              ul.showImages {
                  li,.uploadBlock {
                      width: calc(33.33% - 10px);
                  }
              }
          }
      }
  }
}
@media only screen and (max-width: 991px) {
  .classesTabWrap {
      .bgFormColor {
          ul.outerBlock {
              ul.showImages {
                  li,.uploadBlock {
                      width: calc(50% - 10px);
                  }
              }
          }
      }
  }
}
@media only screen and (max-width: 480px) {
  .classesTabWrap {
      .bgFormColor {
          ul.outerBlock {
              ul.showImages {
                  li,.uploadBlock {
                      width: 100%;
                  }
              }
          }
      }
  }
}
