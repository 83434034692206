.image-upload>input {
  display: none;
}

.font-sm-12 {
  font-size: 12px;
}

.half50 {
  display: flex;
  flex-wrap: wrap;
  // padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  p:nth-child(odd) {
    border-right: 1px solid #eee;
  }
  p {
    width: 50%;
    padding: 10px 15px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    gap: 10px;

    .textCap {
      text-transform: capitalize;
    }
  }

  h4 {
    text-align: center;
    padding-bottom: 10px;
    width: 100%;
    margin-top: 15px;
  }

  .location-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width: 100%;
    border-top: 1px solid #eee;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .wrapmodal .modal-dialog {
    max-width: 600px;
  }
}

@media only screen and (max-width: 991px) {
  .wrapmodal .modal-body {
    padding: 10px;
  }

  .half50 {
    padding: 15px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .half50 p {
    width: 100%;
  }
}