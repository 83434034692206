.image-upload>input {
    display: none;
  }

.font-sm-12{
   font-size: 12px;
}  
.services-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}


/* Default dropdown button color */
.yearDropdown .dropdown-toggle {
  background-color: rgb(248, 249, 252) !important; 
  color: #424b54 !important; 
  border: 1px solid rgb(225, 230, 239) !important;
  margin: 5px !important;
}

.yearDropdown .dropdown-toggle .dropdown-icon {
  color: #424b54 !important;
}

.yearDropdown .dropdown-toggle:hover,
.yearDropdown .dropdown-toggle:focus {
  background-color: #424b54 !important; 
  color: rgb(248, 249, 252) !important; 
  border: 1px solid #424b54 !important; 
}

/* Change icon color on hover/focus */
.yearDropdown .dropdown-toggle:hover .dropdown-icon,
.yearDropdown .dropdown-toggle:focus .dropdown-icon {
  color: rgb(248, 249, 252) !important;
}

/* Custom active selected year color */
.yearDropdown .dropdown-item.active {
  background-color: #424b54 !important;
  color: rgb(248, 249, 252) !important;
}

/* Add scrollbar to the dropdown menu */
.yearDropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto; 
  padding: 0;
}


